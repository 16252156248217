<template>
  <div class="timeline">
    <span class="set">
      <div class="anim" :class="isactive ? 'active' : ''"></div>
      <i></i>
      <i></i>
    </span>
    <div class="timeline_text" @mouseenter="active" @mouseleave="unactive">
      <p class="icon">
        <template v-for="(item, index) in data.number">
          <i class="iconfont" :class="item.title" :key="index"></i>
        </template>
      </p>
      <p>{{ data.content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isactive: false
    }
  },
  props: {
    data: {
      default: []
    }
  },
  methods: {
    active() {
      this.isactive = true
    },
    unactive() {
      this.isactive = false
    }
  }
}
</script>

<style scoped lang="scss" scoped>
.icon {
  margin: 0 1rem;
  i {
    font-size: 1rem;
  }
}
.timeline {
  height: 3.157894rem;
  display: flex;
  align-items: center;
  .timeline_text {
    cursor: pointer;
    display: flex;
    height: 2.368421rem;
    align-items: center;
    font-size: 0.631578rem;
    color: #ffffff;
    img {
      margin: 0 1.315789rem;
      width: 1.578947rem;
      height: 0.789473rem;
    }
    background-image: linear-gradient(to right, rgba(139, 106, 198, 1) 0%, rgba(125, 73, 192, 0.6) 50%, rgba(192, 49, 193, 0) 100%);
    &:hover > img {
      transform: scale(1.5);
    }
    &:hover i {
      font-size: 1.3rem !important;
    }
    &:hover {
      font-size: 0.789473rem;
    }
  }
  span {
    width: 0.526315rem;
    height: 0.526315rem;
    background-color: #ffffff;
    border-radius: 50%;
    position: relative;
    margin-right: 2.368421rem;
    box-shadow: 0px 0px 2px 10px #8368c2;
    i {
      position: absolute;
      &:nth-child(2) {
        height: 2.894736rem;
        width: 0.026315rem;
        background-color: #ffffff;
        top: 100%;
        left: 50%;
      }

      &:last-child {
        width: 2.368421rem;
        height: 0.026315rem;
        background-color: #8368c2;
        top: 50%;
        left: 100%;
      }
    }
    .anim {
      transform: translateY(-50%) translateX(-50%);
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 2px;
      border-radius: 50%;
    }
  }
}
@keyframes mymove {
  from {
    transform: scale(1);
    background: rgba(255, 255, 213, 50);
  }
  to {
    transform: scale(50);
    background: transparent;
  }
}
.set:hover > .anim {
  animation: mymove 0.75s;
}
.active {
  animation: mymove 0.75s;
}
.set:hover + .timeline_text {
  img {
    transform: scale(1.5);
  }
  font-size: 0.789473rem;
}
</style>
