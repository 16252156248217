<template>
  <div class="home">
    <div class="swiper">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="item">
            <div class="item_context" :class="isActive == '0' ? 'fromLeft' : ''">
              <div class="transverse"></div>
              <h2>促进和支持网络安全</h2>
              <div class="item_context_text">维护健康绿色的网络环境</div>
              <div class="item_context_text" style="margin-top: 0.789473rem">保护您的网络财产安全</div>
              <button class="btn" @click="trial">免费试用</button>
            </div>
            <div class="item_img01" :class="isActive == '0' ? 'fromRight' : ''">
              <img src="@/assets/img/pc/home/nav_banner01.png" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="item_context" :class="isActive == '1' ? 'fromLeft' : ''">
              <div class="transverse"></div>
              <h2>有线无线网络认证平台</h2>
              <div class="item_context_text">通过手机验证码等多种方式安全接入网络</div>
              <div class="item_context_text" style="margin-top: 0.789473rem">助力用户快速连接WiFi</div>
              <button class="btn" @click="trial">免费试用</button>
            </div>
            <div class="item_img02" :class="isActive == '1' ? 'fromRight' : ''">
              <img src="@/assets/img/pc/home/nav_banner02.png" />
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="context">
      <div class="why">
        <h2>为什么要选择优享云?</h2>
        <div class="why_swiper">
          <el-carousel indicator-position="none" :autoplay="true" height="19.473684rem" arrow="always" :interval="3000">
            <el-carousel-item>
              <div class="why_swiper_item1 why_swiper_item_ood">
                <img src="@/assets/img/pc/home/swiper01.png" />
                <div class="carousel_item_text">
                  <h3>多种认证方式</h3>
                  <p>
                    手机短信认证，微信公众号认证，钉钉账号认证，账号密码认证（支持关联LDAP、AD域，API接口认证），员工授权认证，二维码识别认证，其他第三方数据源校验完成认证。
                  </p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="why_swiper_item2 why_swiper_item_even">
                <img src="@/assets/img/pc/home/02.png" class="number" />
                <div class="carousel_item_text">
                  <h3>兼容主流品牌</h3>
                  <p>
                    兼容主流有线无线网络设备，如华为、H3C、锐捷、Cisco、Aruba、RUCKUS、TP-LINK。多种品牌设备共存于一个管理系统，去繁存简、兼容并包。
                  </p>
                  <img src="@/assets/img/pc/home/swiper02.jpg" />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="why_swiper_item3 why_swiper_item_ood">
                <img src="@/assets/img/pc/home/swiper03.png" />
                <div class="carousel_item_text">
                  <h3>配置简单</h3>
                  <p>
                    所有配置部署过程均采用向导式配置方式，可视化的认证页面定制，
                    无须专业的网络管理员也可轻松完成平台部署配置工作，并提供视频资料以及文档资料满足更复杂的开发的配置任务。
                  </p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="why_swiper_item4 why_swiper_item_even">
                <img src="@/assets/img/pc/home/04.png" class="number" />
                <div class="carousel_item_text">
                  <h3>旁挂接入</h3>
                  <p>旁挂接入，不影响现有网络拓扑结构。最小化成本实现认证系统接入。轻松实现有线无线统一准入控制，为企业上网安全保驾护航。</p>
                  <img src="@/assets/img/pc/home/swiper04.jpg" />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="process">
        <h2>接入流程</h2>
        <div class="chart">
          <div class="chart_left">
            <div class="chart_left_context">
              <img src="@/assets/img/pc/home/pictures.png" />
              <button type="button" @click="trial">立即咨询</button>
              <div>营销一部：181-5388-6184 (李经理)</div>
              <div>营销二部：150-1036-6164 (彭经理)</div>
              <div>技术支持：189-3245-1909 (李经理)</div>
            </div>
          </div>
          <div class="chart_right">
            <Timeline :data="item" v-for="(item, index) in activities" :key="index"></Timeline>
          </div>
        </div>
      </div>
      <div class="case">
        <h2>近期案例</h2>
        <div class="case_context">
          <div class="swiper">
            <swiper
              :options="swiperOptions2"
              ref="mySwiper2"
              @mouseover.native="mouserover"
              @mouseleave.native="mouseleave"
              v-if="partnerDataList.length"
            >
              <swiper-slide class="case_swiper_item1" v-for="(item, index) in partnerDataList" :key="index">
                <div class="case_swiper_item1_text">
                  <img src="@/assets/img/pc/home/case_swiper_wifi.png" />
                  <h3>{{ item.caseName }}</h3>
                  <div class="casesHtml">
                    <div>{{ handleText(item.contentText) }}</div>
                  </div>
                </div>
                <img :src="generaCompletePath(item.mainImg)" />
              </swiper-slide>
            </swiper>
            <div class="arrowleft icon-arrowleftcirclezuoyuanjiantou iconfont" @click="prev"></div>
            <div class="arrowright icon-arrowrightcircleyouyuanjiantou iconfont" @click="next"></div>
          </div>
        </div>
      </div>
      <div class="partner">
        <h3>合作伙伴</h3>
        <div class="partner_context">
          <div class="partner_context_imgs">
            <div v-for="(item, index) in partnerImgList" :key="index">
              <img :src="item.image" />
            </div>
          </div>
        </div>
      </div>
      <FootMain></FootMain>
    </div>
  </div>
</template>
<script type="text/javascript">
let vm = null
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Timeline from '@/components/pc/timeline/index.vue'
import FootMain from '@/components/pc/commom/footmain/index.vue'
import { BaseURL } from '@/utils/request.js'
export default {
  components: {
    Swiper,
    SwiperSlide,
    Timeline,
    FootMain
  },
  data() {
    return {
      isActive: 999,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        loop: true,
        observer: true,
        loop: true,
        autoplay: {
          delay: 5000, //自动切换的时间间隔，单位ms
          disableOnInteraction: false //用户操作swiper之后，是否禁止autoplay
        },
        on: {
          slideChangeTransitionEnd: function () {
            vm.isActive = JSON.stringify(this.realIndex)
          }
        }
      },
      swiperOptions2: {
        observer: true,
        loop: true,
        autoplay: {
          delay: 3000, //自动切换的时间间隔，单位ms
          disableOnInteraction: false //用户操作swiper之后，是否禁止autoplay
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      activities: [
        {
          content: '提供现场拓扑结构',
          timestamp: '',
          image: require('@/assets/img/pc/home/num01.png'),
          number: [{ title: 'icon-shuzi0' }, { title: 'icon-shuzi1' }]
        },
        {
          content: '根据环境拟定部署方案',
          timestamp: ' ',
          image: require('@/assets/img/pc/home/num02.png'),
          number: [{ title: 'icon-shuzi0' }, { title: 'icon-shuzi2' }]
        },
        {
          content: '搭建现场测试环境',
          timestamp: '',
          image: require('@/assets/img/pc/home/num03.png'),
          number: [{ title: 'icon-shuzi0' }, { title: 'icon-shuzi3' }]
        },
        {
          content: '客户确认签订合同，预付定金',
          timestamp: '',
          image: require('@/assets/img/pc/home/num04.png'),
          number: [{ title: 'icon-shuzi0' }, { title: 'icon-shuzi4' }]
        },
        {
          content: '开发定制需求完成部署',
          timestamp: '',
          image: require('@/assets/img/pc/home/num05.png'),
          number: [{ title: 'icon-shuzi0' }, { title: 'icon-shuzi5' }]
        },
        {
          content: '客户完成验收,支付尾款',
          timestamp: '',
          image: require('@/assets/img/pc/home/num06.png'),
          number: [{ title: 'icon-shuzi0' }, { title: 'icon-shuzi6' }]
        }
      ],
      partnerImgList: [
        { image: require('@/assets/img/pc/home/partner_item01.png') },
        { image: require('@/assets/img/pc/home/partner_item02.png') },
        { image: require('@/assets/img/pc/home/partner_item03.png') },
        { image: require('@/assets/img/pc/home/partner_item04.png') },
        { image: require('@/assets/img/pc/home/partner_item05.png') },
        { image: require('@/assets/img/pc/home/partner_item06.png') },
        { image: require('@/assets/img/pc/home/partner_item07.png') },
        { image: require('@/assets/img/pc/home/partner_item08.png') },
        { image: require('@/assets/img/pc/home/partner_item09.png') },
        { image: require('@/assets/img/pc/home/partner_item10.png') },
        { image: require('@/assets/img/pc/home/partner_item11.png') },
        { image: require('@/assets/img/pc/home/partner_item12.png') }
      ]
    }
  },
  methods: {
    prev() {
      this.$refs.mySwiper2.$swiper.slidePrev()
    },
    next() {
      this.$refs.mySwiper2.$swiper.slideNext()
    },
    active(e) {},
    mouserover() {
      this.$refs.mySwiper2.$swiper.autoplay.stop()
    },
    mouseleave() {
      this.$refs.mySwiper2.$swiper.autoplay.start()
    },
    trial() {
      this.$router.push('/trial')
    },
    generaCompletePath(img) {
      if (img) return `${BaseURL}static${img}`
    },
    handleText(text) {
      let height = text.length
      if (height > 65) {
        let newText = text.slice(0, 65) + '...'
        return newText
      }
      return text
    }
  },
  async created() {
    vm = this
    this.$store.dispatch('getCasesList')
  },
  computed: {
    partnerDataList() {
      return this.$store.state.casesList
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/pc/home/index.scss';
</style>
<style scoped lang="scss">
/deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #7d6bef;
}

/deep/ .is-active > .el-carousel__button {
  width: 25px !important;
}

/deep/ .swiper-pagination-bullet-active {
  border-radius: 5px;
  width: 25px !important;
  background-color: #7d6bef;
}

/deep/ .swiper-pagination-bullet {
  background-color: #beb5f7;
}

.item {
  width: 100%;
  height: 3.9473rem;
}

/deep/ .el-timeline > li:nth-last-child(1) {
  display: none;
}

/deep/ .el-timeline-item__wrapper {
  padding-left: 2.631578rem;
}
</style>
<style>
.casesHtml {
  padding: 0;
  margin: 0;
  height: 7rem;
  overflow: hidden;
  font-size: 0.578947rem;
  color: #666;
  overflow: hidden;
  margin: 0;
  overflow: hidden;
  height: 5.5263rem;
  line-height: 1.1rem;
}
.case_swiper_item1_text {
  text-overflow: ellipsis;
}
.casesHtml p {
  font-size: 0.578947rem;
  color: #666;
  overflow: hidden;
  margin: 0;
  overflow: hidden;
  height: 210px;
  line-height: 0.921052rem;
}
</style>
